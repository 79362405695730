<template>
  <div class="page form" v-loading="loading">
    <h2 v-if="!id">新建帖子</h2>
    <h2 v-else>
      {{ `编辑帖子：${ form.title }` }}
      <copy-link :url="'/pages/post?id=' + form.id"/>
    </h2>

    <section>
      <h3>编辑</h3>
      <el-form :model="form" label-width="100px" :rules="rules" ref="post_form">
        <el-form-item prop="title" label="标题" required>
          <el-input v-model="form.title"/>
        </el-form-item>
        <el-form-item label="封面">
          <input-image-single v-model="form.cover"/>
          <div class="tip">推荐尺寸 宽700 x 高350 的 jpg、png、gif 图片，图片大小推荐 300KB 以内</div>
        </el-form-item>
        <el-form-item label="添加视频">
          <video-comp v-model="form.video_id"/>
        </el-form-item>
        <el-form-item prop="content" label="内容" required>
          <text-editor class="editor" v-model="form.content"/>
          <div class="tip">插图推荐尺寸宽度小于700</div>
        </el-form-item>
        <el-form-item label="设置">
          <el-checkbox label="推荐流" v-model="form.is_recommend"/>
          <el-checkbox label="置顶" v-model="form.is_top"/>
          <el-checkbox label="可添加评论" v-model="form.usable_comment"/>
        </el-form-item>
        <el-form-item prop="i_order" label="推荐页排序">
          <el-input-number v-model="form.i_order_recommend"/>
        </el-form-item>
        <el-form-item label="浏览量">
          <el-input-number v-model="form.stat_visit"/>
        </el-form-item>
        <el-form-item label="点赞量">
          <el-input-number v-model="form.stat_like"/>
        </el-form-item>

        <el-form-item label="附加视频">

        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交
          </el-button>
          <el-button type="danger" @click="onDelete">删除</el-button>
        </el-form-item>
      </el-form>
    </section>

    <section>
      <h3>
        附加视频
        <el-button class="mrl-10" size="small" @click="() => openAddVideoDialog()">添加</el-button>
      </h3>


      <draggable class="list-group" v-model="form.append_videos" group="people"
                 @start="drag=true"
                 @end="drag=false">
        <div class="item" v-for="(v, i) in form.append_videos" :key="v.id">
          <span class="badge">{{ i + 1 }}. </span>
          <span class="content" @click="() => openAddVideoDialog(v)"> {{v.title}} </span>
          <a title="删除" @click="() => onRemoveAppendVideo(v)" class="i-del el-icon-close"/>
        </div>
      </draggable>
      <el-dialog :title="'附加视频'"
                 width="600px" :visible.sync="videoDialogVisible">
        <video-comp v-model="append_video_id"
                    :update="onSubmitAppendVideo"
                    :remove="onRemoveAppendVideo"/>
      </el-dialog>
    </section>
  </div>
</template>

<script>
  import VideosList from '../comps/videos-list'
  import draggable from 'vuedraggable'

  export default {
    components: {
      VideosList,
      draggable
    },
    data() {
      return {
        id: this.$route.query.id,
        loading: false,
        loading2: false,
        append_video_id: '',
        videoDialogVisible: false,
        form: {},
        rules: {
          title: [
            {required: true, message: '请输入标题', trigger: 'blur'}
          ]
        },
        append_videos: [],
        comments: []
      }
    },
    mounted() {
      if (this.id) {
        this.load()
      }
    },
    methods: {
      load() {
        this.loading = true
        this.$api.request('/post/get', {id: this.id}).then(data => {
          this.form = data
          this.loading = false
        })
      },
      onSubmitAppendVideo(v) {
        // todo BUG 不显示标题
        let d = {id: v.id, title: v.title}
        if (!this.form.append_videos) {
          this.form.append_videos = []
        }
        if (!this.form.append_videos.map(r => r.id).includes(d.id)) {
          this.form.append_videos.push(d)
        } else {
          for (let i = 0; i < this.form.append_videos.length; i++) {
            if (this.form.append_videos[i].id === d.id) {
              this.form.append_videos[i] = d
            }
          }
          this.videoDialogVisible = false
        }

        this.onSubmit()
      },
      onRemoveAppendVideo(v) {
        this.form.append_videos = this.form.append_videos.filter(r => r.id !== v.id)
        this.videoDialogVisible = false
      },
      onSubmit() {
        this.$refs.post_form.validate(valid => {

          if (!valid) {
            return this.$message.error('必须填写标题和内容')
          }
          this.loading2 = true
          const path = this.id ? '/post/update' : '/post/create'
          this.$api.request(path, {id: this.id, ...this.form}).then(data => {
            this.$router.push({query: {id: data.id}})
            this.loading2 = false
            this.$message.success('操作成功!')
          })
        })
      },
      onDelete() {
        if (confirm('您确定要删除吗？')) {
          this.$api.request('/post/remove', {id: this.id}).then(data => {
            this.form = data
            this.$message.success('成功删除!')
            this.$router.push({name: 'post-list'})
          })
        }
      },
      openAddVideoDialog(v) {
        if (!v) {
          this.videoDialogVisible = true
          this.append_video_id = ''
        } else {
          this.videoDialogVisible = true
          this.append_video_id = v.id
        }
      },
      onAddVideo() {
        if (this.append_video_id && !this.append_videos.includes(this.append_video_id)) {
          this.append_videos = [...this.append_videos, this.append_video_id]
        }
        this.append_video_id = ''
        this.videoDialogVisible = false
      },
      onRemoveVideo(s) {
        this.append_videos = this.append_videos.filter(a => a !== s)
      },
    }
  }
</script>

<style lang="scss" scoped>
  .list-group {
    margin: 10px 30px;
    max-width: 500px;

    .item {
      line-height: 28px;
      border-radius: 4px;
      cursor: pointer;
      position: relative;
      display: block;
      padding: 5px 10px;
      margin-bottom: -1px;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, .125);
      font-size: 14px;
      color: #606266;

      &:hover {
        background-color: #f3f3f3;
      }

      .content {
        margin: 0 5px;
      }
    }
  }
</style>

