<template>
  <div class="form-image-uploader">
    <el-upload class="_uploader"
               :action="$settings.host + '/upload'"
               :on-success="onSuccess"
               :on-preview="onPreview"
               :on-remove="onRemove">
      <el-button size="small" type="primary">点击上传</el-button>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" style="min-height: 200px">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      value: Array
    },
    model: {
      prop: 'value',
      event: 'change'
    },
    data() {
      return {
        files: [],
        dialogImageUrl: '',
        dialogVisible: false
      }
    },
    mounted() {
      if (!this.value) {
        this.$emit('change', [])
      }
    },
    methods: {
      onChange(e){
      },
      onSuccess(resp, file) {
        this.$emit('change', [...(this.value || []), {url: resp, name: file.name}])
      },
      onRemove(file, fileList) {
        this.$emit('change', fileList.map(r => r.url))
      },
      onPreview(file) {
        window.open(file.url || file.response, '_blank')
        // this.dialogImageUrl = file.url
        // this.dialogVisible = true
      }
    }
  }
</script>
